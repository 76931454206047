import { render, staticRenderFns } from "./Content.vue?vue&type=template&id=f87a5d9e&"
import script from "./Content.vue?vue&type=script&lang=js&"
export * from "./Content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyWelcomePage: function () { return import('/usr/src/set-nuxt/components/Pages/WelcomePage.vue' /* webpackChunkName: "components/welcome-page" */).then(function(m) { return m['default'] || m }) },LazyFollowGroupLanding: function () { return import('/usr/src/set-nuxt/components/Landing/FollowGroupLanding.vue' /* webpackChunkName: "components/follow-group-landing" */).then(function(m) { return m['default'] || m }) },LazyContentCategory: function () { return import('/usr/src/set-nuxt/components/Landing/ContentCategory.vue' /* webpackChunkName: "components/content-category" */).then(function(m) { return m['default'] || m }) },LazyVideoDetail: function () { return import('/usr/src/set-nuxt/components/Pages/VideoDetail.vue' /* webpackChunkName: "components/video-detail" */).then(function(m) { return m['default'] || m }) },LazyDynamicContent: function () { return import('/usr/src/set-nuxt/components/Content/DynamicContent.vue' /* webpackChunkName: "components/dynamic-content" */).then(function(m) { return m['default'] || m }) }})
