//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Content',
  props: {
    dataObj: {
      type: Object,
      default: Object
    }
  }
}
