//
//
//
//

import { mapGetters } from 'vuex'
import seo from '~/mixins/seo'
import embedScript from '~/mixins/embed-script'
import GoogleTagManager from '~/plugins/statistics/google-tag-manager'
export default {
  layout: 'welcome',
  mixins: [seo, embedScript],
  computed: {
    ...mapGetters({ content: 'contents/getContentData' }),
    title () {
      return `${(this.pageSeoData || {}).title} - ${this.content.title}`
    },
    gtmTrackingId () {
      const { advanced } = this.content || {}
      const { gtmTrackingId } = advanced || {}
      return gtmTrackingId
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$nuxt.$loading.finish()
    })
  },
  head () {
    const i18nSeo = this.$nuxtI18nSeo()
    const gtm = new GoogleTagManager(this.gtmTrackingId)
    return {
      title: this.title,
      htmlAttrs: {
        class: this.isGrayscale,
        ...i18nSeo.htmlAttrs
      },
      meta: [
        ...this.getMetaDataWebsite,
        ...i18nSeo.meta
      ],
      noscript: [...gtm.gtmNoScript()],
      script: [
        ...this.getEmbedScriptDataWebsite,
        ...this.getEmbedScriptDataPage,
        ...gtm.gtmScript(),
        ...this.truehitsScript
      ],
      style: [
        ...this.getStyleCssWebsite
      ],
      link: [
        ...this.setLinkWebsite
      ],
      __dangerouslyDisableSanitizers: ['innerHTML']
    }
  }
}
